import React from "react"

function ChevronRight() {
	return (
		<svg viewBox='0 0 94 94' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_75_2)'>
				<path
					d='M54.388 45.9705L31.7993 1.02811C31.4825 0.398044 30.8369 0 30.1309 0H5.67525C5.02785 0 4.42675 0.335329 4.08648 0.885862C3.74622 1.4364 3.7162 2.12333 4.00686 2.70165L26.1753 46.8072L4.00686 90.9127C3.71638 91.4908 3.74622 92.1779 4.0863 92.7285C4.42638 93.279 5.02766 93.6143 5.67525 93.6143H30.1309C30.8371 93.6143 31.4827 93.2163 31.7993 92.5864L54.388 47.644C54.6529 47.1175 54.6529 46.4969 54.388 45.9705ZM28.9793 89.8848H8.70141L29.9323 47.6442C30.197 47.1176 30.197 46.4969 29.9323 45.9705L8.70141 3.72958H28.9793L50.631 46.8072L28.9793 89.8848Z'
					fill='#DC6969'
				/>
				<path
					d='M89.7083 45.9705L67.1196 1.02811C66.8027 0.398044 66.1572 0 65.4512 0H40.9955C40.3479 0 39.7468 0.335329 39.4066 0.885862C39.0665 1.4364 39.0363 2.12333 39.3269 2.70165L61.4954 46.8072L39.3271 90.9127C39.0366 91.4908 39.0665 92.1779 39.4067 92.7285C39.7468 93.279 40.3479 93.6143 40.9957 93.6143H65.4514C66.1573 93.6143 66.8029 93.2163 67.1197 92.5864L89.7084 47.644C89.9731 47.1175 89.9731 46.4969 89.7083 45.9705ZM64.2995 89.8848H44.0217L65.2526 47.6442C65.5173 47.1176 65.5173 46.4969 65.2526 45.9705L44.0217 3.72958H64.2995L85.9509 46.8072L64.2995 89.8848Z'
					fill='#DC6969'
				/>
				<path
					d='M67.8995 17.0655L67.3061 15.885C66.8434 14.9648 65.7215 14.5933 64.8001 15.0553C63.8789 15.5175 63.5068 16.6382 63.9695 17.5586L64.5629 18.739C64.8907 19.391 65.5489 19.7675 66.2326 19.7675C66.5141 19.7675 66.8002 19.7035 67.0689 19.5687C67.9903 19.1065 68.3624 17.9859 67.8995 17.0655Z'
					fill='#DC6969'
				/>
				<path
					d='M82.4274 45.9702L70.8988 23.033C70.4363 22.113 69.3148 21.7416 68.3928 22.2033C67.4716 22.6653 67.0995 23.7862 67.5622 24.7066L79.0908 47.6437C79.4185 48.2957 80.0768 48.6722 80.7605 48.6722C81.042 48.6722 81.3279 48.6084 81.5968 48.4735C82.5182 48.0114 82.8903 46.8906 82.4274 45.9702Z'
					fill='#DC6969'
				/>
			</g>
			<defs>
				<clipPath id='clip0_75_2'>
					<rect width='93.7153' height='93.6143' fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default ChevronRight
