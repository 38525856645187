import React from "react"

function Triptic2() {
	return (
		<svg viewBox='0 0 556 575' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M243.854 216.543L295.284 305.117L243.416 393.944L140.119 394.196L88.6889 305.622L140.556 216.795L243.854 216.543Z'
				fill='#5A6988'
				fillOpacity='0.17'
			/>
			<path
				d='M140.845 217.293L243.563 217.041L294.704 305.118L243.128 393.447L140.41 393.698L89.2687 305.621L140.845 217.293Z'
				stroke='#5A6988'
				strokeOpacity='0.47'
			/>
			<path
				d='M423.86 268.58L475.29 357.154L423.422 445.981L320.125 446.233L268.695 357.659L320.562 268.832L423.86 268.58Z'
				fill='#5A6988'
				fillOpacity='0.17'
			/>
			<path
				d='M320.851 269.33L423.569 269.078L474.71 357.155L423.134 445.484L320.416 445.735L269.275 357.658L320.851 269.33Z'
				stroke='#5A6988'
				strokeOpacity='0.47'
			/>
			<path
				d='M380.417 88.8267L431.847 177.401L379.979 266.228L276.682 266.48L225.252 177.906L277.119 89.0793L380.417 88.8267Z'
				fill='#5A6988'
				fillOpacity='0.17'
			/>
			<path
				d='M277.408 89.5765L380.126 89.3253L431.267 177.402L379.691 265.731L276.973 265.982L225.832 177.905L277.408 89.5765Z'
				stroke='#5A6988'
				strokeOpacity='0.47'
			/>
		</svg>
	)
}

export default Triptic2
