import React from "react"

function ChevronLeft() {
	return (
		<svg viewBox='0 0 94 94' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_75_15)'>
				<path
					d='M39.3273 47.6438L61.916 92.5861C62.2329 93.2162 62.8784 93.6143 63.5844 93.6143H88.0401C88.6875 93.6143 89.2886 93.2789 89.6288 92.7284C89.9691 92.1779 89.9991 91.4909 89.7085 90.9126L67.54 46.8071L89.7085 2.70157C89.999 2.12343 89.9691 1.43631 89.629 0.885779C89.2889 0.335246 88.6877 -8.39233e-05 88.0401 -8.39233e-05H63.5844C62.8782 -8.39233e-05 62.2327 0.397962 61.916 1.02785L39.3273 45.9702C39.0625 46.4968 39.0625 47.1174 39.3273 47.6438ZM64.7361 3.7295H85.0139L63.783 45.97C63.5183 46.4966 63.5183 47.1174 63.783 47.6438L85.0139 89.8847H64.7361L43.0844 46.8071L64.7361 3.7295Z'
					fill='#DC6969'
				/>
				<path
					d='M4.00708 47.6438L26.5958 92.5861C26.9126 93.2162 27.5582 93.6143 28.2642 93.6143H52.7198C53.3674 93.6143 53.9685 93.2789 54.3088 92.7284C54.6489 92.1779 54.6791 91.4909 54.3884 90.9126L32.22 46.8071L54.3882 2.70157C54.6787 2.12343 54.6489 1.43631 54.3086 0.885779C53.9685 0.335246 53.3674 -8.39233e-05 52.7196 -8.39233e-05H28.264C27.558 -8.39233e-05 26.9124 0.397962 26.5956 1.02785L4.00689 45.9702C3.74222 46.4968 3.74222 47.1174 4.00708 47.6438ZM29.4158 3.7295H49.6937L28.4628 45.97C28.1981 46.4966 28.1981 47.1174 28.4628 47.6438L49.6937 89.8847H29.4158L7.76448 46.8071L29.4158 3.7295Z'
					fill='#DC6969'
				/>
				<path
					d='M25.8159 76.549L26.4093 77.7295C26.872 78.6497 27.9938 79.0212 28.9152 78.5592C29.8365 78.097 30.2086 76.9763 29.7459 76.0559L29.1524 74.8755C28.8246 74.2235 28.1664 73.847 27.4828 73.847C27.2013 73.847 26.9152 73.911 26.6465 74.0458C25.7251 74.508 25.353 75.6286 25.8159 76.549Z'
					fill='#DC6969'
				/>
				<path
					d='M11.2879 47.6441L22.8165 70.5812C23.2791 71.5013 24.4006 71.8726 25.3225 71.411C26.2437 70.9489 26.6159 69.8281 26.1531 68.9077L14.6245 45.9705C14.2969 45.3185 13.6385 44.942 12.9548 44.942C12.6733 44.942 12.3874 45.0059 12.1185 45.1408C11.1971 45.6028 10.825 46.7236 11.2879 47.6441Z'
					fill='#DC6969'
				/>
			</g>
			<defs>
				<clipPath id='clip0_75_15'>
					<rect
						width='93.7153'
						height='93.6143'
						fill='white'
						transform='translate(93.7153 93.6143) rotate(-180)'
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default ChevronLeft
