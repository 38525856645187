import React from "react"

function Waves() {
	return (
		<svg viewBox='0 0 900 600' xmlns='http://www.w3.org/2000/svg' version='1.1'>
			<path
				d='M0 122L16.7 121.3C33.3 120.7 66.7 119.3 100 121.5C133.3 123.7 166.7 129.3 200 132.8C233.3 136.3 266.7 137.7 300 133C333.3 128.3 366.7 117.7 400 118.2C433.3 118.7 466.7 130.3 500 136.5C533.3 142.7 566.7 143.3 600 141.7C633.3 140 666.7 136 700 130.3C733.3 124.7 766.7 117.3 800 116.8C833.3 116.3 866.7 122.7 883.3 125.8L900 129L900 0L883.3 0C866.7 0 833.3 0 800 0C766.7 0 733.3 0 700 0C666.7 0 633.3 0 600 0C566.7 0 533.3 0 500 0C466.7 0 433.3 0 400 0C366.7 0 333.3 0 300 0C266.7 0 233.3 0 200 0C166.7 0 133.3 0 100 0C66.7 0 33.3 0 16.7 0L0 0Z'
				fill='#ffffff'></path>
			<path
				d='M0 87L16.7 91.8C33.3 96.7 66.7 106.3 100 110C133.3 113.7 166.7 111.3 200 108.5C233.3 105.7 266.7 102.3 300 99.3C333.3 96.3 366.7 93.7 400 93C433.3 92.3 466.7 93.7 500 95.3C533.3 97 566.7 99 600 100.2C633.3 101.3 666.7 101.7 700 103.5C733.3 105.3 766.7 108.7 800 107.5C833.3 106.3 866.7 100.7 883.3 97.8L900 95L900 0L883.3 0C866.7 0 833.3 0 800 0C766.7 0 733.3 0 700 0C666.7 0 633.3 0 600 0C566.7 0 533.3 0 500 0C466.7 0 433.3 0 400 0C366.7 0 333.3 0 300 0C266.7 0 233.3 0 200 0C166.7 0 133.3 0 100 0C66.7 0 33.3 0 16.7 0L0 0Z'
				fill='#f1f4f6'></path>
			<path
				d='M0 86L16.7 85.8C33.3 85.7 66.7 85.3 100 83.8C133.3 82.3 166.7 79.7 200 79.5C233.3 79.3 266.7 81.7 300 80.2C333.3 78.7 366.7 73.3 400 72.5C433.3 71.7 466.7 75.3 500 76.2C533.3 77 566.7 75 600 73.3C633.3 71.7 666.7 70.3 700 69C733.3 67.7 766.7 66.3 800 69.2C833.3 72 866.7 79 883.3 82.5L900 86L900 0L883.3 0C866.7 0 833.3 0 800 0C766.7 0 733.3 0 700 0C666.7 0 633.3 0 600 0C566.7 0 533.3 0 500 0C466.7 0 433.3 0 400 0C366.7 0 333.3 0 300 0C266.7 0 233.3 0 200 0C166.7 0 133.3 0 100 0C66.7 0 33.3 0 16.7 0L0 0Z'
				fill='#e4eaee'></path>
			<path
				d='M0 45L16.7 46C33.3 47 66.7 49 100 50.8C133.3 52.7 166.7 54.3 200 52.7C233.3 51 266.7 46 300 47.8C333.3 49.7 366.7 58.3 400 61.2C433.3 64 466.7 61 500 59.2C533.3 57.3 566.7 56.7 600 56.7C633.3 56.7 666.7 57.3 700 55.7C733.3 54 766.7 50 800 49.3C833.3 48.7 866.7 51.3 883.3 52.7L900 54L900 0L883.3 0C866.7 0 833.3 0 800 0C766.7 0 733.3 0 700 0C666.7 0 633.3 0 600 0C566.7 0 533.3 0 500 0C466.7 0 433.3 0 400 0C366.7 0 333.3 0 300 0C266.7 0 233.3 0 200 0C166.7 0 133.3 0 100 0C66.7 0 33.3 0 16.7 0L0 0Z'
				fill='#d7e0e6'></path>
			<path
				d='M0 34L16.7 33.2C33.3 32.3 66.7 30.7 100 28.2C133.3 25.7 166.7 22.3 200 22.7C233.3 23 266.7 27 300 29.8C333.3 32.7 366.7 34.3 400 33C433.3 31.7 466.7 27.3 500 25.2C533.3 23 566.7 23 600 24.7C633.3 26.3 666.7 29.7 700 30.8C733.3 32 766.7 31 800 30.2C833.3 29.3 866.7 28.7 883.3 28.3L900 28L900 0L883.3 0C866.7 0 833.3 0 800 0C766.7 0 733.3 0 700 0C666.7 0 633.3 0 600 0C566.7 0 533.3 0 500 0C466.7 0 433.3 0 400 0C366.7 0 333.3 0 300 0C266.7 0 233.3 0 200 0C166.7 0 133.3 0 100 0C66.7 0 33.3 0 16.7 0L0 0Z'
				fill='#c9d6de'></path>
		</svg>
	)
}

export default Waves
